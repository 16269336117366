<template>
  <a-modal v-model:visible="visible" title="账号设置" :footer="null" >
   
      <a-form :model="formState" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-item required  label="账号名称">
          <a-input v-model:value="formState.username" />
      </a-form-item>
      <a-form-item :required="formState.form_type == 'new'" label="登录密码">
          <a-input v-model:value="formState.password" />
      </a-form-item>
      <a-form-item required label="角色">
          <a-select
          v-model:value="formState.role"
          >
          <a-select-option :value='i' v-for='i of Object.keys(UserRoleMap)' :key='i' >
            {{UserRoleMap[i]}}
          </a-select-option>
          </a-select>
      </a-form-item>
      <a-form-item required label="账号状态">
        <a-switch v-model:checked="formState.status" checkedValue=1 unCheckedValue=0  />
      </a-form-item>
      
      <template v-if="formState.role == 4" >
        <a-form-item required label="博主名称">
          <a-input v-model:value="formState.blogger_name" />
        </a-form-item>
        <a-form-item required label="博主平台">
          <a-input v-model:value="formState.blogger_platform" />
        </a-form-item>
        <a-form-item required label="博主分类">
          <a-input v-model:value="formState.blogger_category" />
        </a-form-item>
        <a-form-item required label="主页地址">
          <a-input type="url" v-model:value="formState.home_url" />
        </a-form-item>
        <a-form-item required label="博主来源">
          <a-select
          v-model:value="formState.source"
          >
          <a-select-option :value='i' v-for='i of bloggerSources' :key='i' >
            {{i}}
          </a-select-option>
          </a-select>
        </a-form-item>
         <a-form-item required v-if="formState.source == '推荐'" label="推荐人">
          <a-input v-model:value="formState.recommender" />
        </a-form-item>
      </template>
      <template v-if="formState.role == 1" >
         <a-form-item required label="运营平台">
            <a-select
              v-model:value="formState.platform"
              >
              <a-select-option :value='v' v-for='v in PlatformEnum' :key='v' >
                {{v}}
              </a-select-option>
              </a-select>
        </a-form-item>
      </template>
      <template v-if="formState.role == 2" >
        <a-form-item  label="绑定商务">
          <a-select
            v-model:value="formState._director_ids"
            :options="directorSuggestion"
            @search="searchDirectors"
            @change="onChangeDirectors"
            mode="multiple"
            placeholder="留空不更新"
          />
        </a-form-item>
      </template>
      <a-form-item :wrapper-col="{ span: 14, offset: 6 }" >
          <a-button type="primary" @click="onSubmit">确认</a-button>
          <a-button style="margin-left:40px" @click="onCancle">取消</a-button>
      </a-form-item>
      </a-form>
   
  </a-modal>
 
</template>

<script >
import { defineComponent, reactive,ref, watch} from 'vue';
import  { toRefs} from 'vue';
import {UserRoleMap, PlatformEnum} from '@/utils/macro'
import request from '@/utils/request'
import { message } from 'ant-design-vue';

import md5 from 'js-md5'

let _searchDirectorDebounce
export default defineComponent({
  emits:['update:visible', 'cancel'],
  props: {
    record: Object
  },
  data(){
    return {
      UserRoleMap,
      PlatformEnum,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },

      directorSuggestion:[],
     
    }
  },
  mounted(){
    console.log('account editor mounted')
    this.searchDirectors()
  },
  methods: {
    onChangeDirectors(item, options){
      let ids = []
      for(let v of options) {
        ids.push(v.id)
      }
      this.formState.director_ids = ids.join(',')
    },
    
    searchDirectors(name) {
      clearTimeout(_searchDirectorDebounce)
      _searchDirectorDebounce =  setTimeout(() => {
        request.get(`/api/users/directors`, {
          username: name
        }).then((rsp) => {
          this.directorSuggestion = []
          for (let v of rsp.data.list) {
            this.directorSuggestion.push({
              value: v.username,
              id: v.id
            })
          }
        })
      }, 200)
    },
    async onSubmit(){
      if (this.formState.form_type == 'edit') {
        let param = {
          ...this.formState,
          role: +this.formState.role,
          status: + this.formState.status,
          password: this.formState.password ? md5(this.formState.password) : ''
        }
        if (param.password === "") {
          delete(param.password)
        }

        await request.put(`/api/users/${this.record.id}`, param)
      } else {
        if (!this.formState.username || 
        !this.formState.password ||
        !this.formState.role) {
          return message.error("请填写必填信息")
        }
        let param = {
          ...this.formState,
          role: +this.formState.role,
          status: + this.formState.status,
          password: md5(this.formState.password)
        }
       
        await request.post('/api/users',param )
      }
     
      this.$emit("success")
    },
    onCancle: function () {
      this.$emit("update:visible", false)
    }
  },
  setup(props, context) {
    console.log('setup')
    const { visible, record } = toRefs(props)
    const initialForm = {
      form_type: 'new',
      username: '',
      password: '',
      director_ids: '',
      role: 1,
      remark: '',
      status: 0,
      platform: '', // 运营专属

      blogger_name: '',
      blogger_platform: '',
      blogger_category: '',
      home_url : '',
      source: '',
      recommender: '',

      _director_ids: [],

      
    }
    let formState = reactive({
      ...initialForm
    })
    let originFormState = {
      ...initialForm
    }
    watch(props, (newProps, oldProps) => {
      console.log(newProps, oldProps)
      if (newProps.record.form_type == 'new') {
        for (let item in initialForm) {
          formState[item] = '' + initialForm[item];
        }
        return
      } else {
        formState.form_type = 'edit'
      }
      for (let item in newProps.record) {
        formState[item] = '' + newProps.record[item];
      }
      formState['password'] = ''
      formState['_director_ids'] = []
      originFormState = newProps.record
     
    });
    
    return {
      bloggerSources: [
        "自荐",
        "推荐"
      ],
      visible,
      formState,
      originFormState
    };
  },
});
</script>