import { createApp } from 'vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import Antd from 'ant-design-vue';
import App from './App.vue';
import 'ant-design-vue/dist/antd.css';

const app = createApp(App);
// app.config.productionTip = false;

app.use(Antd);

app.use(store).use(router).mount('#app')

document.title = "图书关键词管理系统"