<template>
  <a-card title="账号管理">
    <a-space   >
      <a-form layout="inline" :model="formSearch" style="margin: 10px auto;"   >
        <a-form-item>
          <a-input placeholder="账号名" v-model:value="formSearch.query" />
        </a-form-item>
        <a-form-item>
          <a-button
            type="primary"
            @click="handleTableChange"
            shape="circle"
          >
          <template #icon><SearchOutlined /></template>
          </a-button>
        </a-form-item>
      </a-form>
      <a-button  v-if="user_role == 1" type='primary' @click="showAccountEditor('new')" >新建账号</a-button>

    </a-space>
    
    <a-alert :message="noticeMessage" type="info" show-icon style="text-align: left;"  />
    <a-table 
    class="ant-table-striped"
    :row-key="record => record.id"
    :data-source="dataSource" 
    :columns="columns" 
    :pagination="pagination"
    :loading="loading"
    @change="handleTableChange"
    :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)"
    >
      <template #role="{ record }">
        <a>{{ UserRoleMap[record.role] }}</a>
      </template>
       <template #status="{ record }">
        <span>
          <a-tag v-if="record.status == 1" color="green"  >{{ StatusMap[record.status] }}</a-tag>
          <a-tag  v-else >{{ StatusMap[record.status] }}</a-tag>
        </span>
      </template>
      <template #action="{ record }" >
        <span  v-if="user_role == 1" >
          <a @click="showAccountEditor('edit', record)" >编辑</a>
          <a-divider type="vertical" />
          <a v-if="record.status == 0" @click="handleEnable(record, 1)">启用</a>
          <a v-else @click="handleEnable(record, 0)">不启用</a>
        </span>
      </template>
    </a-table>
  </a-card>

  <AccountEditor v-model:visible="accountEditorVisible"  @success="handleAccountEditorSuccess" :record="accountEditorInitial"  />

</template>
<script>
import { defineComponent, reactive, ref } from 'vue'
import AccountEditor from '@/components/AccountEditor.vue'
import { SearchOutlined } from '@ant-design/icons-vue';
import request from '@/utils/request'
import {  UserRoleMap } from '@/utils/macro'
import { Modal } from 'ant-design-vue';
import { useStore } from 'vuex'


const StatusMap  = {
  0: '不可用',
  1: '可用',
}

export default defineComponent({
  components: {
    AccountEditor,
    SearchOutlined
  },
  data(){
    return {
      UserRoleMap,
      loading: false,
      dataSource: [],
      pagination: {
        total: 1,
        current: 0,
        pageSize: 10,
        pageSizeOptions: ['10', '20', '50', '100']
      },
      noticeMessage: '',
      formSearch: {
        query: ''
      },

      accountEditorInitial: {
        username: 'test',
        password: '',
        blogger_name: '',
        bloggers: [],
        role: '1',
        remark: '',
        status: 0,
      },
      
    }
  },
  mounted(){
    this.handleTableChange()
  },
  computed:{
    user_role: () => {
      const store = useStore()
      return store.state.user.role
    }
  },
  methods: {
     
    async handleTableChange (pagi = {pageSize: 10, current: 1}) {
      this.loading = true
      const rsp = await request.get('/api/users', {
        offset: (pagi.current - 1) * pagi.pageSize ,
        limit: pagi.pageSize,
        blogger_name: this.formSearch.query
      })
      this.loading = false
      this.dataSource = [].concat(rsp.data.list)
      this.noticeMessage = `共 ${rsp.data.pagination.total} 条`
      this.pagination.total = rsp.data.pagination.total
      this.pagination.current = pagi.current
    },
    handleEnable: async function(record, status){
      let text = status == 1 ? `确认要启用` : `确认要关闭`
      Modal.confirm({
        content: () => `${text}「${record.username}」吗？`,
        onOk: () => {
          request.put(`/api/users/${record.id}`, {
            status: status
          }).then(() => {
            this.handleTableChange()
          })
        },
        onCancel() {
          Modal.destroyAll();
        },
      });
     
    },
    handleAccountEditorSuccess: function() {
      this.accountEditorVisible = false
      this.handleTableChange()
    },
    showAccountEditor: function(type, initial = {}) {
      this.accountEditorVisible = true

      
      this.accountEditorInitial = reactive({
        form_type: type,
        ...initial,
      })
    }
  },
  setup() {
    const accountEditorVisible = ref(false);


    return {
      accountEditorVisible,
      StatusMap,
      columns: [
        {
          title: '账号 ID',
          dataIndex: 'id',
          key: 'id',
        },
        {
          title: '账号名',
          dataIndex: 'username',
          key: 'username',
        },
        {
          title: '状态',
          dataIndex: 'status',
          key: 'status',
          slots: {customRender: 'status'}
        },
        {
          title: '角色',
          dataIndex: 'role',
          key: 'role',
          slots: { customRender: 'role' },
        },
          {
          title: '平台',
          dataIndex: 'platform',
          key: 'platform',
        },
        {
          title: '绑定博主',
          dataIndex: 'blogger_name',
          key: 'blogger_name',
        },
         {
          title: '备注',
          dataIndex: 'remark',
          key: 'remark',
        },
        {
          title: '操作',
          key: 'action',
          fixed: 'right',
          width: 200,
          slots: { customRender: 'action' },
        },
      ],
    };
  },
})
</script>

<style scoped>
.ant-table-striped :deep(.table-striped) td {
  background-color: #fafafa;
}
</style>