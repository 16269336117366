<template>

  <a-card title="关键词投放数据">
   
    <a-space>
     <a-form :model="formSearch" :label-col=" { span: 4 }" :wrapper-col="{ span: 14}" style="margin: 10px auto;" >
        <a-form-item label="博主" >
          <a-select
            v-model:value="formSearch._blogger_ids"
            :options="bloggerSuggestion"
            @search="searchBloggers"
            @change="onChangeBloggers"
            mode="multiple"
            placeholder="博主名称"
            style="width: 200px"
          >
          </a-select>
        </a-form-item>

        <a-form-item  label="商务">
            <a-select
            v-model:value="formSearch._director_ids"
            :options="directorSuggestion"
            @search="searchDirectors"
            @change="onChangeDirectors"
            mode="multiple"
            placeholder="商务名称"
          />
        </a-form-item>
        <a-form-item label="关键词" >
          <a-select
            v-model:value="formSearch._keyword_ids"
            :options="keywordsSuggestion"
            @search="searchKeywords"
            @change="onChangeKeywords"
            mode="multiple"
            placeholder="关键词"
            style="width: 200px"
          >
          </a-select>
        </a-form-item>
         <a-form-item label="平台" >
          <a-select
            v-model:value="formSearch.platform"
            placeholder="平台"
            style="width: 200px"
          >
            <a-select-option :value='i' v-for='i of ConstPlatform' :key='i' >
              {{i}}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="统计日期" >
          <a-range-picker
            v-model:value="formSearch.apply_time_range"
            :disabledDate="disabledDateRangeDate"
            @change="onDateRangeChange"
            @openChange="onDateRangeOpenChange"
            @calendarChange="onDateRangeCalendarChange"
          />
        </a-form-item>
      
        
      <a-form-item>
        <a-button
          type="primary"
          @click="handleTableChange()"
        >
         搜索
        </a-button>
      </a-form-item>
     
    </a-form>
    </a-space>
    
    <a-alert :message="noticeMessage" type="info" show-icon style="text-align: left;"  />

    <a-table 
    size="samll"
    class="ant-table-striped"
    :row-key="record => record.keyword_id"
    :data-source="dataSource" 
    :columns="columns" 
    :pagination="pagination"
    :loading="loading"
    @change="handleTableChange"
    :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)"
    >
      <template #role="{ record }">
        <a>{{ RoleMap[record.role] }}</a>
      </template>
       <template #status="{ record }">
        <span>
          <a-tag v-if="record.status == 1" color="green"  >{{ KeywordStatusMap[record.status] }}</a-tag>
          <a-tag v-else-if="record.status == 3" color="red"  >{{ KeywordStatusMap[record.status] }}</a-tag>
          <a-tag  v-else-if="record.status == 2" color="orange" >{{ KeywordStatusMap[record.status] }}</a-tag>
          <a-tag v-else   >{{ KeywordStatusMap[record.status] }}</a-tag>
        </span>
      </template>
      <template #publish_url="{ record }" >
        <a
          v-if="record.status == 3"
          @click="handleShowPublishedURL(record)"
        >
        查看
        </a>
      </template>
      <template #sum_stats="{ record }" >
        <span v-if="record.sum_stats"  >{{record.sum_stats}}</span>
      </template>
       <template #created_at="{ record }" >
        <span >{{moment(record.created_at).format('YYYY-DD-MM hh:mm:ss')}}</span>
      </template>
    </a-table>
  </a-card>

</template>
<script >
import { defineComponent, ref, reactive, watch} from 'vue'

import { message } from 'ant-design-vue';
import request from '@/utils/request'

import { Modal } from 'ant-design-vue';
import {UserRoleMap, KeywordStatusMap} from '@/utils/macro'
import _ from 'lodash'
import { useRoute } from 'vue-router';
import { useStore } from 'vuex'
import moment from 'moment'

let _searchBloggerDebounce = null 
let _searchKeywordsDebounce = null
let _searchDirectorDebounce = null

let pageTypeTitle = {
  "manage": "关键词列表",
  "apply": '关键词申请',
  'stats': '关键词数据'
}

export default defineComponent({
  components: {},
  data(){
    return {
      moment,
      UserRoleMap,
      KeywordStatusMap,
      pageType: "manage",
      pageTitle: "关键词列表",

      loading: false,
      dataSource: [],
      pagination: {
        total: 1,
        current: 0,
        pageSize: 10,
        pageSizeOptions: ['10', '20', '50', '100']
      },
      noticeMessage: '',

      formSearch: {
        query: '',
        _blogger_ids: [],
        _keyword_ids: [],
        _director_ids: [],
        blogger_id: '',
        keyword_id: '',
        director_id: '',
        platform: '',
        apply_time_range: [],
        status: "-1" ,
      },

      bloggerSuggestion: [],
      keywordsSuggestion: [],
      directorSuggestion: [],

    }
  },
  computed:{
    user_role: () => {
      const store = useStore()
      return store.state.user.role
    }
  },
  watch:{
    keywordStatus: function (v, ov){
      this.handleTableChange()
    },
    $route: {
       handler(route,oldval){
        if (route.query.type) {
          this.pageType = route.query.type
        }
        this.pageTitle = pageTypeTitle[this.pageType]
        console.log(this.pageType);//新路由信息

      },
      // 深度观察监听
      deep: true
    }
  },
  mounted(){
    const route = useRoute()
    if (route.query.type) {
      this.pageType = route.query.type
      this.pageTitle = pageTypeTitle[this.pageType]
    }

    this.searchBloggers()
    this.searchKeywords()
    this.searchDirectors()
    this.handleTableChange()
  },
  methods: {
    onChangeDirectors(item, options){
      if (options.length > 1) {
        message.error("只能选择一项")
        this.formSearch._director_ids = [this.formSearch._director_ids[0]]
        return false
      }
      let ids = []
      for(let v of options) {
        ids.push(v.id)
      }
      this.formSearch.director_id = ids.join(',')
    },
    
    searchDirectors(name) {
      clearTimeout(_searchDirectorDebounce)
      _searchDirectorDebounce =  setTimeout(() => {
        request.get(`/api/users/directors`, {
          director_name: name
        }).then((rsp) => {
          this.directorSuggestion = []
          for (let v of rsp.data.list) {
            this.directorSuggestion.push({
              value: v.username,
              id: v.id
            })
          }
        })
      }, 200)
    },

    onChangeBloggers(item, options){
     if (options.length > 1) {
        message.error("只能选择一项")
        this.formSearch._blogger_ids = [this.formSearch._blogger_ids[0]]
        return
      }
      let ids = []
      for(let v of options) {
        ids.push(v.id)
      }
      this.formSearch.blogger_id = ids.join(',')
    },
    
    searchBloggers(bloggerName) {
      clearTimeout(_searchBloggerDebounce)
      _searchBloggerDebounce =  setTimeout(() => {
          request.get(`/api/bloggers`, {
            blogger_name: bloggerName
          }).then((rsp) => {
            this.bloggerSuggestion = []
            for (let v of rsp.data.list) {
              this.bloggerSuggestion.push({
                value: v.blogger_name,
                id: v.user_id
              })
            }
          })
        }, 600)
      
    },
     searchKeywords(name) {
       clearTimeout(_searchKeywordsDebounce)
       _searchKeywordsDebounce = setTimeout(() => {
          request.get(`/api/keywords`, {
            text: name,
            status: -1
          }).then((rsp) => {
            this.keywordsSuggestion = []
            for (let v of rsp.data.list) {
              this.keywordsSuggestion.push({
                value: v.text,
                id: v.id
              })
            }
          })
        }, 600)
    },
    onChangeKeywords(item, options){
      if (options.length > 1) {
        message.error("只能选择一项")
        this.formSearch._keyword_ids = [this.formSearch._keyword_ids[0]]
        return
      }
      let ids = []
      for(let v of options) {
        ids.push(v.id)
      }
      this.formSearch.keyword_id = ids.join(',')
    },
    
    async handleTableChange (pagi = {pageSize: 10, current: 1}) {
      this.loading = true

      let timeRange = []
      for (let t of this.formSearch.apply_time_range) {
        timeRange.push(moment([t.year(),t.month() ,t.date()]).unix())
      }
      const rsp = await request.get('/api/stats/keyword_sum', {
        offset: (pagi.current - 1) * pagi.pageSize ,
        limit: pagi.pageSize,
        status: + this.keywordStatus,
        ...this.formSearch,
        apply_time_range: timeRange.join(','),
      })
      this.loading = false
      this.dataSource = [].concat(rsp.data.list)
      this.noticeMessage = `共 ${rsp.data.pagination.total} 条`
      this.pagination.total = rsp.data.pagination.total
      this.pagination.current = pagi.current
    },
  },
  setup(props, context) {
    const applyTimeRange = ref([]);
    const momentValue = ref();

    
    const disabledDateRangeDate = (current) => {
      if (!applyTimeRange.value || applyTimeRange.value.length === 0) {
        return false;
      }
      const diffDate = current.diff(applyTimeRange.value[0], 'days');
      return Math.abs(diffDate) > 7;
    };
     const onDateRangeOpenChange = (open) => {
      if (open) {
        applyTimeRange.value = [];
      }
    };

    const onDateRangeChange = (val) => {
      momentValue.value = val;
    };

    const onDateRangeCalendarChange = (val) => {
      applyTimeRange.value = val;
    };

    return {
      ConstPlatform: ['七猫', 'UC', '其他'],
      disabledDateRangeDate,
      onDateRangeOpenChange,
      onDateRangeChange,
      onDateRangeCalendarChange,
      columns: [
        {
          title: '关键词',
          dataIndex: 'keyword_name',
          key: 'keyword_name',
        },
         {
          title: '博主',
          dataIndex: 'blogger_name',
          key: 'blogger_name',
        },
        {
          title: '商务',
          dataIndex: 'director_name',
          key: 'director_name',
        },
        {
          title: '平台',
          dataIndex: 'platform',
          key: 'platform',
        },
         
         {
          title: '数据',
          dataIndex: 'stats_sum',
          key: 'stats_sum',
        }
      ],
    };
  },
})
</script>

<style scoped>
.ant-table-striped :deep(.table-striped) td {
  background-color: #fafafa;
}
</style>