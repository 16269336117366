<template>
  <a-card title="博主管理">
    <a-space >
      <a-form layout="inline" :model="formSearch" style="margin: 10px auto;" >
        <a-form-item>
          <a-input placeholder="博主名" v-model:value="formSearch.query" />
        </a-form-item>
        <a-form-item>
          <a-button
            type="primary"
            @click="handleTableChange"
            shape="circle"
          >
          <template #icon><SearchOutlined /></template>
          </a-button>
        </a-form-item>
      </a-form>
      <template  v-if="user_role == 1"  >
        <a-button type="primary" href="/blogger_tmp.csv" >下载模板</a-button>
        <a-button type="primary" @click="handleImportBloggers" >导入博主</a-button>
      </template>
      
      <input ref='uploadInput' style="width:0px;" type= "file" class="dl-none" name="icon" @change="onChangeFile"/>
    </a-space>
    <a-alert :message="noticeMessage" type="info" show-icon style="text-align: left;"  />
    <a-table 
    class="ant-table-striped"
    :row-key="record => record.id"
    :data-source="dataSource" 
    :columns="columns" 
    :pagination="pagination"
    :loading="loading"
    @change="handleTableChange"
    :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)"
    >
      <template #username="{ record }">
        <span>{{ record.username ? record.username : '-' }}</span>
      </template>
      <template #director_name="{ record }">
        <span>{{ record.director_name ? record.director_name : '-' }}</span>
      </template>
   
    </a-table>
  </a-card>

  <AccountEditor v-model:visible="accountEditorVisible" @cancle="accountEditorVisible=false" />

</template>
<script>
import { defineComponent,  onMounted} from 'vue'
import AccountEditor from '@/components/AccountEditor.vue'
import { SearchOutlined } from '@ant-design/icons-vue';
import axios from 'axios'
import { message } from 'ant-design-vue';
import request from '@/utils/request'
import { Modal } from 'ant-design-vue';
import { useStore } from 'vuex'


export default defineComponent({
  components: {
    AccountEditor,
    SearchOutlined
  },
  data(){
    return {
      loading: false,
      dataSource: [],
      pagination: {
        total: 1,
        current: 0,
        pageSize: 10,
        pageSizeOptions: ['10', '20', '50', '100']
      },
      noticeMessage: '',

       formSearch: {
        query: ''
      },
    }
  },
  mounted(){
    this.handleTableChange()
  },
  computed:{
    user_role: () => {
      const store = useStore()
      return store.state.user.role
    }
  },
  methods: {
    handelDelete(record){
      Modal.confirm({
        content: () => `确认删除 ${record.blogger_name} 吗？操作不可恢复，但是可以重新添加`,
        onOk: () => {
          request.delete(`/api/bloggers/${record.id}`).then(() => {
            this.handleTableChange()
          })
        },
        onCancel() {
          Modal.destroyAll();
        },
      });
    },
    async handleTableChange (pagi = {pageSize: 10, current: 1}) {
      this.loading = true
      const rsp = await request.get('/api/bloggers', {
        offset: (pagi.current - 1) * pagi.pageSize ,
        limit: pagi.pageSize,
        blogger_name: this.formSearch.query
      })
      this.loading = false
      this.dataSource = [].concat(rsp.data.list)
      this.noticeMessage = `共 ${rsp.data.pagination.total} 条`
      this.pagination.total = rsp.data.pagination.total
      this.pagination.current = pagi.current
    },
    handleImportBloggers(){
      this.uploadInput.click()
    },
    async onChangeFile(e){
      let files = e.target.files;
      if(files){
        var forms = new FormData()
        var configs = {
          headers:{'Content-Type':'multipart/form-data'}
        };
        forms.append('file', files[0])
              
        axios.post(`/api/bloggers/import`, forms ,configs).then((rsp) => {
          message.success(`博主信息更新成功, 成功更新 ${rsp.data.data.count} 条，失败博主: ${rsp.data.data.fails.join(",")}`)
          this.handleTableChange()
        }).catch((err) => {
          let msg = err
          if (err.response && err.response.data && err.response.data.error) {
            msg = err.response.data.error.message
          }
          message.error(`博主信息更新失败: ${msg}`)
          this.handleTableChange()
        })
        
       
      }

    }
  },
  setup(props, context) {
   
    return {
      uploadInput: null,
      columns: [
        {
          title: '关联用户账号',
          dataIndex: 'username',
          key: 'username',
          slots: { customRender: 'username' },
        },
         {
          title: '关联用户 UID',
          dataIndex: 'user_id',
          key: 'user_id',
        },
        {
          title: '名称',
          dataIndex: 'blogger_name',
          key: 'blogger_name',
        },
         {
          title: '分类',
          dataIndex: 'blogger_category',
          key: 'blogger_category',
        },
        {
          title: '博主平台',
          dataIndex: 'blogger_platform',
          key: 'blogger_platform',
        },
        {
          title: '推广平台',
          dataIndex: 'home_url',
          key: 'home_url',
        },
        
       
      ],
    };
  },
})
</script>

<style scoped>
.ant-table-striped :deep(.table-striped) td {
  background-color: #fafafa;
}
</style>