import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Login from '../views/Login.vue'
import Home from '../views/Home.vue'

import BasicProfile from '../views/BasicProfile.vue'
import AccountManage from '../views/AccountManage.vue'
import BloggerManage from '../views/BloggerManage.vue'
import BookManage from '../views/BookManage.vue'
import KeywordManage from '../views/KeywordManage.vue'
import KeywordManageMini from '../views/KeywordManageMini.vue'
import KeywordBloggerStats from '../views/KeywordBloggerStats.vue'
import StatsKeyword from '../views/StatsKeyword.vue'
import StatsKeywordSum from '../views/StatsKeywordSum.vue'
import StatsDataBlogger from '../views/StatsDataBlogger.vue'
import StatsDataDirector from '../views/StatsDataDirector.vue'
import Dashboard from '../views/Dashboard.vue'

import MobileHome from '../views/m/Home.vue'
import MobileLogin from '../views/m/Login.vue'
import MobileProfile from '../views/m/Profile.vue'
import MobileKeyword from '../views/m/Keyword.vue'
import MobileKeywordEditor from '../views/m/KeywordEditor.vue'
import MobileStatsKeyword from '../views/m/StatsKeyword.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    alias: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/keywords',
    name: 'Keywords',
    component: Home,
    children: [
      {
        path: 'login',
        name: 'kLogin',
        component:Login
      },
      {
        path: 'profile',
        name: 'BasicProfile',
        component:BasicProfile
      },
      {
        path: 'accounts',
        name: 'AccountManage',
        component:AccountManage
      },
      {
        path: 'bloggers',
        name: 'BloggerManage',
        component:BloggerManage
      },
      {
        path: 'books',
        name: 'BookManage',
        component:BookManage
      },
      {
        path: 'manage',
        name: 'KeywordManage',
        component:KeywordManage
      },
      {
        path: 'manage_apply',
        name: 'KeywordManageApply',
        component:KeywordManage
      },
      {
        path: 'manage_audit',
        name: 'KeywordManageAudit',
        component:KeywordManage
      },
      {
        path: 'manage_keyword_stats',
        name: 'KeywordManageStats',
        component:KeywordManage
      },
      {
        path: 'manage_blogger_stats',
        name: 'KeywordBloggerStats',
        component:KeywordBloggerStats
      },
      {
        path: 'stats_keyword',
        name: 'StatsKeyword',
        component: StatsKeyword
      },
      {
        path: 'stats_keyword_sum',
        name: 'StatsKeywordSum',
        component: StatsKeywordSum
      },
      {
        path: 'stats_data_blogger',
        name: 'StatsDataBlogger',
        component: StatsDataBlogger
      },
      {
        path: 'stats_data_director',
        name: 'StatsDataDirector',
        component: StatsDataDirector
      },
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: Dashboard
      },
      
      
    ]
  },
  {
    path: '/mobile/login',
    name: 'MobileLogin',
    component: MobileLogin
  },
  {
    path: '/mobile',
    name: 'Mobile',
    component: MobileHome,
    children: [
    
      {
        path: 'profile',
        name: 'MobileProfile',
        component:MobileProfile
      },
      {
        path: 'keyword',
        name: 'MobileKeyword',
        component:MobileKeyword
      },
      {
        path: 'apply',
        name: 'MobileKeywordEditor',
        component:MobileKeywordEditor
      },
      {
        path: 'stats_keyword',
        name: 'MobileStatsKeyword',
        component:MobileStatsKeyword
      },
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
