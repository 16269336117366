<template>
  <div >
   
    <a-menu
      v-model:openKeys="openKeys"
      v-model:selectedKeys="selectedKeys"
      mode="inline"
      theme="dark"
      :inline-collapsed="collapsed"
    >
      <a-sub-menu key="basic">
        <template #icon>
          <PieChartOutlined />
        </template>
        <template #title>基础信息管理</template>
        <a-menu-item key="/keywords/profile">
            
            <router-link :to="{ path: '/keywords/profile'}">
              个人设置
            </router-link>
        </a-menu-item>
        <a-menu-item v-if="user_role == 1" key="/keywords/accounts">
            <router-link :to="{ path: '/keywords/accounts'}">
              账号管理
            </router-link>
        </a-menu-item>
        <a-menu-item  v-if="[1,2,3].indexOf(user_role) > -1"  key="/keywords/bloggers">
            <router-link :to="{ path: '/keywords/bloggers'}">
              博主管理
            </router-link>
        </a-menu-item>
        <a-menu-item  v-if="user_role == 1"  key="/keywords/books">
            <router-link :to="{ path: '/keywords/books'}">
              书库管理
            </router-link>
        </a-menu-item>
      </a-sub-menu>
      <a-sub-menu key="keywords">
        <template #icon>
          <AppstoreOutlined />
        </template>
        <template #title>关键词信息管理</template>
        <a-menu-item  v-if="user_role == 1"  key="/keywords/manage?type=manage">
          <router-link :to="{ path: '/keywords/manage', query: { type: 'manage' }}">
            关键词列表
          </router-link>
        </a-menu-item>
        <a-menu-item key="/keywords/manage_apply">
          <router-link :to="{ path: '/keywords/manage?type=apply', query: { type: 'apply' }}">
            关键词申请
          </router-link>
        </a-menu-item>
        
      </a-sub-menu>
      <a-sub-menu key="stats">
        <template #icon>
          <AppstoreOutlined />
        </template>
        <template #title>数据统计</template>
         <a-menu-item  key="/keywords/stats_keyword">
            <router-link :to="{ path: '/keywords/stats_keyword'}">
              关键词每日统计
            </router-link>
        </a-menu-item>
        <a-menu-item  key="/keywords/stats_keyword_sum">
            <router-link :to="{ path: '/keywords/stats_keyword_sum'}">
              关键词累计统计
            </router-link>
        </a-menu-item>
        <a-menu-item v-if="[1,2,3].indexOf(user_role) > -1"  key="/keywords/stats_data_blogger">
            <router-link :to="{ path: '/keywords/stats_data_blogger'}">
              博主数据统计
            </router-link>
        </a-menu-item>
        <a-menu-item v-if="[1,2,3].indexOf(user_role) > -1"  key="/keywords/stats_data_director">
            <router-link :to="{ path: '/keywords/stats_data_director'}">
              商务数据统计
            </router-link>
        </a-menu-item>
        <a-menu-item v-if="[1,2,3].indexOf(user_role) > -1"  key="/keywords/dashboard">
            <router-link :to="{ path: '/keywords/dashboard'}">
              核心指标看板
            </router-link>
        </a-menu-item>
      </a-sub-menu>
    </a-menu>
  </div>
</template>
<script >
import { defineComponent, reactive, toRefs, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router'
import {useStore} from 'vuex'

import {
  PieChartOutlined,
  AppstoreOutlined,
} from '@ant-design/icons-vue';


export default defineComponent({
  components: {
    PieChartOutlined,
    AppstoreOutlined,    
  },
  computed:{
    user_role: () => {
      const store = useStore()
      return store.state.user.role
    }
  },
  watch: {
    $route: {
       handler(route,oldval){
        this.selectedKeys = [route.path]
      },
      // 深度观察监听
      deep: true
    }
  },
  setup() {
    let route = useRoute()

    const state = reactive({
      collapsed: false,
      selectedKeys: [route.path],
      openKeys: ['basic', 'keywords', 'stats'],
      preOpenKeys: ['basic'],
    });

    watch(
      () => state.openKeys,
      (val, oldVal) => {
        state.preOpenKeys = oldVal;
      },
    );
    const router = useRouter()
    watch (
        () => state.selectedKeys,
        (val, oldVal) => {
            state.selectedKeys = val
            console.log(val)
            
        }
    )
    const toggleCollapsed = () => {
      state.collapsed = !state.collapsed;
      state.openKeys = state.collapsed ? [] : state.preOpenKeys;
    };

    return {
      ...toRefs(state),
      toggleCollapsed,
    };
  },
});
</script>