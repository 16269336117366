<template>
  <a-card title="书库管理">
    <a-space>
     <a-form layout="inline" :model="formSearch" style="margin: 10px auto;" >
      <a-form-item>
        <a-input placeholder="书名" v-model:value="formSearch.query" />
      </a-form-item>
      <a-form-item>
        <a-button
          type="primary"
           @click="handleTableChange"
          shape="circle"
        >
         <template #icon><SearchOutlined /></template>
        </a-button>
      </a-form-item>
    </a-form>
    <template  v-if="user_role == 1"  >
      <a-button type="primary" href="/books.csv" >下载模板</a-button>
      <a-button type="primary" @click="handleImportBloggers" >导入书库</a-button>
      <input ref='uploadInput' style="visibility: hidden;" type= "file" class="dl-none" name="icon" @change="onChangeFile"/>
    </template>
    </a-space>
    <a-alert :message="noticeMessage" type="info" show-icon style="text-align: left;"  />
    <a-table 
    class="ant-table-striped"
    :row-key="record => record.id"
    :data-source="dataSource" 
    :columns="columns" 
    :pagination="pagination"
    :loading="loading"
    @change="handleTableChange"
    :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)"
    >
      <template #action="{ record }" >
        <span v-if="user_role == 1">
          <a @click="handelDelete(record)" >删除 {{record.username}}</a>
          <a-divider type="vertical" />
          <a @click="showAccountEditor" >编辑</a>
         
        </span>
      </template>
    </a-table>
  </a-card>


</template>
<script>
import { defineComponent,  onMounted} from 'vue'
import { SearchOutlined } from '@ant-design/icons-vue';
import axios from 'axios'
import { message } from 'ant-design-vue';
import request from '@/utils/request'
import { Modal } from 'ant-design-vue';
import { useStore } from 'vuex'

export default defineComponent({
  components: {
        SearchOutlined
      },
  data(){
    return {
      loading: false,
      dataSource: [],
      pagination: {
        total: 1,
        current: 0,
        pageSize: 10,
        pageSizeOptions: ['10', '20', '50', '100']
      },
      noticeMessage: '',

       formSearch: {
        query: ''
      },
    }
  },
  mounted(){
    this.handleTableChange()
  },
  computed:{
    user_role: () => {
      const store = useStore()
      return store.state.user.role
    }
  },
  methods: {
    handelDelete(record){
      Modal.confirm({
        content: () => `确认删除「${record.book_name}」吗？操作不可恢复，但是可以重新添加`,
        onOk: () => {
          request.delete(`/api/books/${record.id}`).then(() => {
            this.handleTableChange()
          })
        },
        onCancel() {
          Modal.destroyAll();
        },
      });
    },
    async handleTableChange (pagi = {pageSize: 10, current: 1}) {
      this.loading = true
      const rsp = await request.get('/api/books', {
        offset: (pagi.current - 1) * pagi.pageSize ,
        limit: pagi.pageSize,
        book_name: this.formSearch.query
      })
      this.loading = false
      this.dataSource = [].concat(rsp.data.list)
      this.noticeMessage = `共 ${rsp.data.pagination.total} 条`
      this.pagination.total = rsp.data.pagination.total
      this.pagination.current = pagi.current
    },
    handleImportBloggers(){
      this.uploadInput.click()
    },
    async onChangeFile(e){
      let files = e.target.files;
      if(files){
        var forms = new FormData()
        var configs = {
          headers:{'Content-Type':'multipart/form-data'}
        };
        forms.append('file', files[0])
        this.uploadInput = null
        axios.post(`/api/books/import`, forms ,configs).then((rsp) => {
          message.success(`书库信息更新成功, 成功更新 ${rsp.data.data.count} 条，失败书籍: ${rsp.data.data.fails.join(",")}`)
          this.handleTableChange()
        }).catch((err) => {
          let msg = err
          if (err.response && err.response.data && err.response.data.error) {
            msg = err.response.data.error.message
          }
          message.error(`书库信息更新失败: ${msg}`)
          this.handleTableChange()
        })
        
       
      }

    }
  },
  setup() {
  

    return {
      uploadInput: null,
      columns: [
        {
          title: '书 ID',
          dataIndex: 'id',
          key: 'id',
        },
        {
          title: '书名',
          dataIndex: 'book_name',
          key: 'book_name',
        },
        {
          title: '平台 ID',
          dataIndex: 'platform_token',
          key: 'platform_token',
        },
        {
          title: '作者',
          dataIndex: 'author',
          key: 'author',
        },
          {
          title: '频道',
          dataIndex: 'channel',
          key: 'channel',
        },
        {
          title: '操作',
          key: 'action',
          fixed: 'right',
          width: 200,
          slots: { customRender: 'action' },
        },
      ],
    };
  },
})
</script>

<style scoped>
.ant-table-striped :deep(.table-striped) td {
  background-color: #fafafa;
}
</style>