<template>
    <a-card :title="'关键词申请'">
      <a-form ref="formRef" :model="formState" @submit="onSubmit" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-item  required label="发布平台">
          <a-select
          :disabled="formState.form_type=='edit'"
          v-model:value="formState.platform"
          >
          <a-select-option :value='i' v-for='i of ConstPlatform' :key='i' >
            {{i}}
          </a-select-option>
          </a-select>
      </a-form-item>
      <a-form-item required  label="关键词">
          <a-input v-model:value="formState.text" />
      </a-form-item>

      
      <a-form-item  v-if="formState.platform=='七猫'" required label="图书">
        <a-select
          v-if="formState.form_type=='new'" 
          v-model:value="formState._book_ids"
          :options="booksSuggestion"
          @search="searchBooks"
          @change="onChangeBooks"
          mode="multiple"
          placeholder="图书名称"
        />
        <a-input disabled v-else v-model:value="formState.book_name"/>
      </a-form-item>
      <a-form-item v-if="[1,2,3].indexOf(user_role)  > -1"  required label="博主">
        <a-select
          v-if="formState.form_type=='new'" 
          v-model:value="formState._blogger_ids"
          :options="bloggerSuggestion"
          @search="searchBloggers"
          @change="onChangeBloggers"
          mode="multiple"
          placeholder="博主名称"
        />
        <a-input disabled v-else v-model:value="formState.blogger_name"/>
      </a-form-item>
      <!-- <a-form-item v-if="formState.form_type == 'edit'"  label="发布链接">
          <a-input type="url" placeholder="http(s)://"  v-model:value="formState.publish_url"/>
      </a-form-item> -->
      <a-form-item required v-if="formState.platform=='UC'"  label="干预方式（UC）">
          <a-select
          v-model:value="formState.intervene"
          >
          <a-select-option :value='v.value' v-for='v of ConstIntervene' :key='v.value' >
            {{ v.value }}
          </a-select-option>
          </a-select>
      </a-form-item>
      <template  v-if="formState.platform=='UC' && formState.intervene==UC_INTERVENE_SC ">
        <a-form-item  required label="书名">
          <a-input   v-model:value="formState.book_name"/>
        </a-form-item>
        <a-form-item  required label="作者名">
          <a-input   v-model:value="formState.book_author"/>
        </a-form-item>
      </template>

      <template  v-else-if="formState.platform=='UC' && formState.intervene==UC_INTERVENE_NO_SC">
        <a-form-item  required label="书名">
          <a-input   v-model:value="formState.book_name"/>
        </a-form-item>
        <a-form-item  required label="作者名">
          <a-input   v-model:value="formState.book_author"/>
        </a-form-item>
        <a-form-item  required label="网页 URL">
          <a-input type="url" placeholder="http(s)://"   v-model:value="formState.book_url"/>
        </a-form-item>
        <a-form-item  required label="原文第一句">
          <a-textarea   v-model:value="formState.content_summary"/>
        </a-form-item>
      </template>
       <template  v-else-if="formState.platform=='UC' && formState.intervene==UC_INTERVENE_URL">
        <a-form-item  required label="书 URL">
          <a-input  type="url" placeholder="http(s)://"   v-model:value="formState.book_url"/>
        </a-form-item>
      </template>

       <a-form-item v-if="[3].indexOf(user_role) == -1" required  label="商务">
           <a-select
          v-model:value="formState._director_ids"
          :options="directorSuggestion"
          @search="searchDirectors"
          @change="onChangeDirectors"
          mode="multiple"
          placeholder="商务名称"
        />
      </a-form-item>

    
      <a-form-item label="备注">
          <a-input v-model:value="formState.remark" />
      </a-form-item>
      
      <a-form-item  style="text-align: center;" >
          <a-button shape="round" size="large"  type="primary" html-type="submit" >新建</a-button>
      </a-form-item>
      </a-form>
    </a-card>
 
</template>

<script >
import { defineComponent, reactive,ref, watch} from 'vue';
import  { toRefs} from 'vue';
import {UserRoleMap} from '@/utils/macro'
import request from '@/utils/request'
import { message } from 'ant-design-vue';
import { useStore } from 'vuex'
import {useRouter} from 'vue-router'

let _searchBooksDebounce, _searchBloggerDebounce, _searchDirectorDebounce
let UC_INTERVENE_SC = "普通干预"
let UC_INTERVENE_NO_SC = "强制干预第一位"
let UC_INTERVENE_URL = "网页拦截"
const initialForm = {
      form_type: 'new',
      platform: '七猫',
      text: '',
      book_id: '',
      blogger_id: '',
      intervene: '',
      book_url: '',
      remark: '',
      director_id: '',

      _book_ids: [],
      _blogger_id: [],
      _director_ids: [],
    }
export default defineComponent({
  props: {
    record: Object
  },
  data(){
    return {
      UserRoleMap,
      UC_INTERVENE_SC,
      UC_INTERVENE_NO_SC,
      UC_INTERVENE_URL,
      booksSuggestion: [],
      bloggerSuggestion: [],
      directorSuggestion: [],

      labelCol: { span: 6 },
      wrapperCol: { span: 12 },

      formState: {
        ...initialForm,
      }
     
    }
  },
  computed:{
    user_role: () => {
      const store = useStore()
      return store.state.user.role
    }
  },
  mounted(){
    console.log('account editor mounted')
    // this.searchBooks()
    this.searchBloggers()
    this.searchDirectors()
  },
  watch:{
    record: function(v1, v2) {
      this.formState = {
        ...initialForm,
        ...v1,
      }
      this.booksSuggestion= []
      // this.bloggerSuggestion= []
      // this.directorSuggestion= []
    }
  },
  methods: {
    onChangeDirectors(item, options){
      if (options.length > 1) {
        message.error("只能选择一项")
        this.formState._director_ids = [this.formState._director_ids[0]]
        return false
      }
      let ids = []
      for(let v of options) {
        ids.push(v.id)
      }
      this.formState.director_id = ids.join(',')
    },
    
    searchDirectors(name) {
      clearTimeout(_searchDirectorDebounce)
      _searchDirectorDebounce =  setTimeout(() => {
        request.get(`/api/users/directors`, {
          director_name: name
        }).then((rsp) => {
          this.directorSuggestion = []
          for (let v of rsp.data.list) {
            this.directorSuggestion.push({
              value: v.username,
              id: v.id
            })
          }
        })
      }, 200)
    },
    onChangeBloggers(item, options){
      if (options.length > 1) {
        message.error("只能选择一项")
        this.formState._blogger_ids = [this.formState._blogger_ids[0]]
        return false
      }
      console.log(item, options)
      let ids = []
      for(let v of options) {
        ids.push(v.id)
      }
      this.formState.blogger_id = ids.join(',')
    },
    
    searchBloggers(bloggerName) {
      clearTimeout(_searchBloggerDebounce)
      _searchBloggerDebounce =  setTimeout(() => {
        request.get(`/api/bloggers`, {
          blogger_name: bloggerName
        }).then((rsp) => {
          this.bloggerSuggestion = []
          for (let v of rsp.data.list) {
            this.bloggerSuggestion.push({
              value: v.blogger_name,
              id: v.user_id
            })
          }
        })
      }, 200)
    },
    searchBooks(bookName) {
      clearTimeout(_searchBooksDebounce)
      _searchBooksDebounce = setTimeout(() => {
        request.get(`/api/books`, {
          book_name: bookName
        }).then((rsp) => {
          this.booksSuggestion = []
          for (let v of rsp.data.list) {
            this.booksSuggestion.push({
              value: v.book_name,
              id: v.id
            })
          }
        })
      }, 200)
    },
    onChangeBooks(item, options){
      if (options.length > 1) {
        message.error("只能选择一项")
        this.formState._book_ids = [this.formState._book_ids[0]]
        return false
      }
      console.log(item, options)
      let ids = []
      for(let v of options) {
        ids.push(v.id)
      }
      this.formState.book_id = ids.join(',')
    },
    async onSubmit(){
      if (!this.formState.platform || 
        !this.formState.text) {
        return message.error("请填写关键词和平台")
      }
      if (this.user_role == 3 && !this.formState.blogger_id) {
        return message.error("请填写商务")
      }
      if (this.formState.platform == 'UC' && this.formState.text.length <5) {
        return message.error("UC 平台关键词需要大于等于 5 个字")
      } else if (this.formState.platform == '七猫' && this.formState.text.length <4) {
        return message.error("七猫 平台关键词需要大于等于 4 个字")
      }
      if (this.formState.form_type == 'edit') {
        await request.put(`/api/keywords/${this.record.id}`, {
          ...this.formState,
          book_id: +this.formState.book_id,
          blogger_id: +this.formState.blogger_id,
          director_id: +this.formState.director_id,
        })
      } else {
        
        await request.post('/api/keywords', {
          ...this.formState,
          book_id: +this.formState.book_id,
          blogger_id: +this.formState.blogger_id,
          director_id: +this.formState.director_id,
        })
      }
     
      this.$router.push({
        path: '/mobile/keyword'
      })
    }
  },
  setup(props, context) {
    console.log('setup keyword editor')
    const { visible, record } = toRefs(props)
    const router = useRouter()
    
    return { 
      $router: router,
      ConstPlatform: ['七猫', 'UC', '其他'],
      ConstIntervene: [
        {
          value: UC_INTERVENE_SC, label: '关键词、书名、作者',
        },
        {        
          value: UC_INTERVENE_NO_SC, label: '关键词、书名、作者、原链接、原文第一句话',
        },
        {
          value: UC_INTERVENE_URL, label: '关键词、原链接',
        }
      ],
      visible
    };
  },
});
</script>