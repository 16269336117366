
import { defineComponent, reactive, onMounted } from 'vue';
import { message } from 'ant-design-vue';
import { useRouter, useRoute } from 'vue-router'
import request from './utils/request'
import { useStore } from 'vuex'

export default defineComponent({
  setup() {
    
    let router = useRouter()
    let route = useRoute()
    const store = useStore()
    console.log('path', route.path)
    request.get(
      '/api/users/auth'
    ).then((res: any) => {
      store.commit("setLogin", res.data)
    }).catch((res) => {
      console.log("未登录", res)
      router.push({
        path: "/"
      })
    })

    console.log('init')
    return {
     
    };
  },
});
