<template>
  <a-modal v-model:visible="visible" title="关键词发布" :footer="null" >
   
      <a-form ref="formRef" :model="formState" @submit="onSubmit" :label-col="labelCol" :wrapper-col="wrapperCol">
    
      <a-form-item   label="关键词">
          <a-input disabled v-model:value="formState.text" />
      </a-form-item>
      <a-form-item required label="发布平台">
          <a-select
          v-model:value="platformOptions"
          :options="platformSuggestion"
          @search="searchPlatform"
          @change="onChangePlatform"
          mode="multiple"
          placeholder="平台"
        />
      </a-form-item>
     
      <a-form-item required label="视频类型">
          <a-select
          v-model:value="artworkTypeOptions"
          :options="artworkSuggestion"
          @search="searchArtwork"
          @change="onChangeArtwork"
          mode="multiple"
          placeholder="媒体类型"
        />
      </a-form-item>
      <a-form-item  required label="发布链接">
          <a-input type="url" placeholder="http(s)://"  v-model:value="formState.publish_url"/>
      </a-form-item>
       <a-form-item required label="发布日期">
        <a-date-picker v-model:value="formState._publish_date" />
      </a-form-item>
      <a-form-item :wrapper-col="{ span: 14, offset: 6 }" >
          <a-button type="primary" html-type="submit" >发布</a-button>
          <a-button style="margin-left:40px" @click="onCancle">取消</a-button>
      </a-form-item>
      </a-form>
   
  </a-modal>
 
</template>

<script >
import { defineComponent, reactive,ref, watch} from 'vue';
import  { toRefs} from 'vue';
import request from '@/utils/request'
import { message } from 'ant-design-vue';



export default defineComponent({
  props: {
    record: Object
  },
  data(){
    return {
      labelCol: { span: 6 },
      wrapperCol: { span: 12 },
      platformSuggestion: [
        {
          value: "抖音",
        },
        {
          value: "快手",
        },
        {
          value: "小红书",
        },
        {
          value: "其他",
        },
      ],
      platformOptions: [],
      artworkTypeOptions: [],
      artworkSuggestion: [
        {
          value: "小说",
        },
        {
          value: "漫画",
        },
        {
          value: "壁纸头像",
        },
        {
          value: "动漫漫画",
        },
        {
          value: "影视",
        },
        {
          value: "其他",
        },
      ]
     
    }
  },
  methods: {
    searchPlatform(text){
      this.platformSuggestion = [
        {
          value: text
        }
      ]
    },
    onChangePlatform(item, options){
      if (options.length > 1) {
        message.error("只能选择一项")
        this.platformOptions = [this.platformOptions[0]]
        return false
      }
      this.formState.publish_platform = options[0].value
    },
    searchArtwork(text) {
      this.artworkSuggestion = [
        {
          value: text
        }
      ]
    },
    onChangeArtwork(text, options){
      if (options.length > 1) {
        message.error("只能选择一项")
        this.artworkTypeOptions = [this.artworkTypeOptions[0]]
        return false
      }
      this.formState.artwork_type = options[0].value
    },
    async onSubmit(){
      if (!this.formState.publish_url
      || !this.formState.publish_platform 
      || !this.formState.artwork_type) {
        return message.error("请填写必填信息")
      }
      await request.put(`/api/keywords/publish/${this.record.id}`, {
        ...this.formState,
        publish_date: this.formState._publish_date.format('YYYY-MM-DD')
      })
      this.$emit("ok")
    },
    onCancle: function () {
      this.$emit("cancel", false)
    }
  },
  setup(props, context) {
    const { visible, record } = toRefs(props)
    const initialForm = {
      text: '',
      publish_platform: '',
      artwork_type: '',
      publish_url: '',
      _publish_date: null,
    }
    let formState = reactive({
      ...initialForm
    })
    watch(props, (newProps, oldProps) => {
      formState['text'] = newProps.record.text
      formState['publish_url'] = ''
      formState['_publish_date'] = null
    });
    
    return { 
      visible,
      formState
    };
  },
});
</script>