
import { defineComponent, reactive, toRaw } from 'vue';
import type { UnwrapRef } from 'vue';
import request from '@/utils/request'
import {useRouter, useRoute} from 'vue-router'
import {useStore} from 'vuex'
import { message } from 'ant-design-vue';
import md5 from 'js-md5'
import { isMobile } from '@/utils/client'

interface FormState {
  username: string;
  password: string;
}
export default defineComponent({
  setup() {
    const router = useRouter()
    const route = useRoute()
   
    const formState: UnwrapRef<FormState> = reactive({
      username: '',
      password: '',
    });
    if (isMobile()) {
        router.push({
          path: '/mobile/login'
        })
        return {
          formState
        }
    }
    const store = useStore()
    const onSubmit = async () => {
      const ret: any = await request.post('/api/login', {
        username: formState.username,
        password: md5(formState.password)
      })
      if (ret.success) {
        message.success("登录成功")
        store.commit("setLogin", ret.data)
        router.push({
          path: '/keywords/dashboard'
        })
      }
      console.log('submit!', toRaw(formState));
    };
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      formState,
      onSubmit,
    };
  },
});
