<template>
  <a-card title="个人设置"  >
        <a-form :model="formState" style="max-width: 600px;" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-item label="账号名称">
        <a-input disabled v-model:value="formState.username" />
      </a-form-item>
      <a-form-item label="角色" style="text-align: left;">
        <a-select  style="width: 90px"
        disabled
          v-model:value="formState.role"
        >
          <a-select-option :value='i' v-for='i of Object.keys(UserRoleMap)' :key='i' >
            {{UserRoleMap[i]}}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="绑定博主">
        <a-input disabled v-model:value="formState.blogger_name" />
      </a-form-item>
      <a-form-item label="登录密码">
        <a-input v-model:value="formState.password" type="password" />
      </a-form-item>
       <a-form-item label="再次输入登录密码">
        <a-input v-model:value="formState.password_confirm" type="password" />
      </a-form-item>
    
      <a-form-item style="text-align: center;"  >
        <a-button shape="round" size="large"  type="primary"  @click="onSubmit">编辑</a-button>
      </a-form-item>
    </a-form>
    <div style="text-align: center;" >
      <a-button type="link" @click="handleLogout">退出登录</a-button>
    </div>
  </a-card>
 
</template>

<script >
import { defineComponent, reactive, toRaw } from 'vue';
import { useStore } from 'vuex'
import { message } from 'ant-design-vue';
import request from '@/utils/request'
import {UserRoleMap} from '@/utils/macro'
import md5 from 'js-md5'


export default defineComponent({
  data(){
    return {
      formState: {
        username: '',
        password: '',
        password_confirm: '',
        blogger_names: '',
        role: 0,
      }
    }
  },
  mounted(){
    const store = useStore()
    this.formState = {
      username: store.state.user.username,
      password: '',
      password_confirm: '',
      blogger_name: store.state.user.blogger_name,
      role: "" + store.state.user.role,
    }
  },
  methods: {
    handleLogout(){
      request.get(`/api/users/logout`).then(() => {
        location.href="/mobile/login"
      }).catch(() => {
        location.href="/mobile/login"
      })
    },
    onSubmit: function(){
      if (this.formState.password != this.formState.password_confirm) {
        message.error("新密码两次输入不一致，请重新输入")
        return
      }
      request.put('/api/users/password', {
        password: md5(this.formState.password),
        password_confirm: md5(this.formState.password_confirm),
      })
    }
  },
  setup() {
    return {
      UserRoleMap,
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
    };
  },
});
</script>