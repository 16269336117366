<template>
  <div >
   
    <a-menu
      v-model:openKeys="openKeys"
      v-model:selectedKeys="selectedKeys"
       mode="horizontal"
       theme="dark"
      :inline-collapsed="collapsed"
    >
     
      <a-menu-item  key="/mobile/keyword">
        <router-link :to="{ path: '/mobile/keyword'}">
          词列表
        </router-link>
      </a-menu-item>
      <a-menu-item key="/mobile/apply">
        <router-link :to="{ path: '/mobile/apply'}">
          申请词
        </router-link>
      </a-menu-item>
      <a-menu-item key="/mobile/stats_keyword">
        <router-link :to="{ path: '/mobile/stats_keyword'}">
          数据
        </router-link>
      </a-menu-item>
       <a-menu-item key="/basic/profile">
        <router-link :to="{ path: '/mobile/profile'}">
          个人设置
        </router-link>
      </a-menu-item>
    </a-menu>
  </div>
</template>
<script >
import { defineComponent, reactive, toRefs, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router'
import {useStore} from 'vuex'


export default defineComponent({
  computed:{
    user_role: () => {
      const store = useStore()
      return store.state.user.role
    }
  },
  watch:{
    $route: {
       handler(route,oldval){
        this.selectedKeys = [route.path]
      },
      // 深度观察监听
      deep: true
    }
  },
  setup() {
    let route = useRoute()

    const state = reactive({
      collapsed: false,
      selectedKeys: [route.path],
      openKeys: ['basic', 'keywords'],
      preOpenKeys: ['basic'],
    });

    watch(
      () => state.openKeys,
      (val, oldVal) => {
        state.preOpenKeys = oldVal;
      },
    );
    const router = useRouter()
    watch (
        () => state.selectedKeys,
        (val, oldVal) => {
            state.selectedKeys = val
            console.log(val)
            
        }
    )
    const toggleCollapsed = () => {
      state.collapsed = !state.collapsed;
      state.openKeys = state.collapsed ? [] : state.preOpenKeys;
    };

    return {
      ...toRefs(state),
      toggleCollapsed,
    };
  },
});
</script>