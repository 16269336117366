<template>
  <a-modal  width='750px'  v-model:visible="visible" title="发布链接" >
      <a-table :dataSource="dataSource" :columns="columns" :pagination="false" >
         <template #created_at="{ record }" >
          <span >{{moment(record.created_at).format('YYYY-MM-DD hh:mm:ss')}}</span>
        </template>
      </a-table>
   
  </a-modal>
 
</template>

<script >
import { defineComponent, ref, toRefs, watch} from 'vue';
import moment from 'moment';

export default defineComponent({
  props: {
    record: Object
  },
  data(){
    return {
    }
  },
  methods: {
    moment,
  },
  setup(props, context) {
    let dataSource = ref([])
    const { visible, record } = toRefs(props)
    watch(props, (newProps, oldProps) => {
      console.log(newProps, oldProps)
      dataSource.value = newProps.record
     
    });
    
    return { 
      dataSource,
      columns:[
        {
          title: "平台",
          dataIndex: 'platform',
          key: 'platform'
        },
        {
          title: "地址",
          dataIndex: 'published_url',
          key: 'published_url'
        },
        {
          title: "类型",
          dataIndex: 'artwork_type',
          key: 'artwork_type'
        },
        {
          title: "发布日期",
          dataIndex: 'published_date',
          key: 'published_date'
        },
        {
          title: "发布时间",
          dataIndex: 'created_at',
          key: 'created_at',
          slots: { customRender: 'created_at' },
        }
      ],
      visible,
    };
  },
});
</script>