

const UserRoleMap = {
  1: '运营',
  2: '商务主管',
  3: '商务',
  4: '博主'
}


const KeywordStatusMap = {
  '0': '待审核',
  '1': '已通过',
  '3': '已发布',
  '2': '未通过'
}

const PlatformEnum = [
  '七猫', 'UC', '其他'
]

export  {
  PlatformEnum,
  UserRoleMap,
  KeywordStatusMap
}